import React from 'react';
import spinnerImg from '../../../assets/spinnerRight.gif';
import './Spinner.css';

const spinner = () => (
  <div className="Spinner">
    <img src={spinnerImg} alt="Loading..." />
  </div>
);

export default spinner;
