import React from 'react';
import styled from 'styled-components';
import { mockMode } from '../../configuration/configuration';
import NavigationItems from '../Navigation/NavigationItems';
import SideMenuToggle from '../Navigation/SideMenuToggle/SideMenuToggle';

const StyledHeader = styled.header`
  margin: 0 auto;
  //border: cadetblue solid 1px;
`;

const HidingNavigation = styled.div`
  @media (max-width: 499px) {
    display: none;
  }
`;

const header = props => {
  const { isAuthenticated, toggleSideMenu } = props;

  return (
    <StyledHeader mockMode={mockMode}>
      <HidingNavigation>
        <NavigationItems isAuthenticated={isAuthenticated} />
      </HidingNavigation>
      <SideMenuToggle clicked={toggleSideMenu} />
    </StyledHeader>
  );
};

export default header;
