import React from 'react';
import styled from 'styled-components';
import Button from '../UI/Button/Button';
import ListEditorModal from '../UI/ListEditorModal/ListEditorModal';
import './DeleteModal.css';

const ButtonBar = styled.div`
  display: flex;
  justify-content: center;
`;

const DeleteModal = ({
  visible,
  confirm,
  cancel,
  listDeleting,
  listItemDeleting,
}) => {
  let caption = '';

  if (listItemDeleting) {
    caption = listItemDeleting.listItemText;
  }

  if (listDeleting) {
    caption = listDeleting;
  }

  return visible ? (
    <ListEditorModal>
      <>
        <div className="DeleteModal">
          Naozaj chcete zmazať:
          <br />
          <strong>{caption}</strong>?
        </div>
        <ButtonBar>
          <Button visible clicked={confirm}>
            Zmazať
          </Button>
          <Button visible clicked={cancel}>
            Zrušiť
          </Button>
        </ButtonBar>
      </>
    </ListEditorModal>
  ) : null;
};

export default DeleteModal;
