import React from 'react';
import './IconButton.css';

const iconButton = props => (
  <div className="IconButton" onClick={props.clicked}>
    <img src={props.icon} alt={props.altName} />
  </div>
);

export default iconButton;
