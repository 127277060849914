import React from 'react';
import Input from '../UI/Input/Input';
import Button from '../UI/Button/Button';
import './ListModal.css';

const listModal = props => {
  const {
    isListModalVisible,
    listModalInputChangeHandler,
    listNameModalInputValue,
    onListSubmit,
    onListModalCancel,
    listModalAction,
  } = props;

  const submitCaption =
    listModalAction === 'updateList' ? 'Zmeniť názov zoznamu' : 'Pridať zoznam';
  const listModalTitle =
    listModalAction === 'updateList'
      ? 'Zmeňte názov zoznamu'
      : 'Vytvorte nový zoznam';

  return isListModalVisible ? (
    <div className="ListModal">
      {listModalTitle}
      <Input
        inputChanged={listModalInputChangeHandler}
        value={listNameModalInputValue}
      />
      <Button visible clicked={onListSubmit}>
        {submitCaption}
      </Button>
      <Button visible clicked={onListModalCancel}>
        Zrušiť
      </Button>
    </div>
  ) : null;
};

export default listModal;
