import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import * as actions from '../../store/actions/index';
import Spinner from '../../components/UI/Spinner/Spinner';
import './Auth.css';
import { timestamping } from '../../configuration/configuration';
import { version } from '../../../package.json';

const StyledPasswordResetModal = styled.div`
  color: ${props => props.theme.mainDark};
  margin: 10px auto 10px auto;
  padding: 15px;
  text-align: center;
  border: 1px solid darkgray;
  box-sizing: border-box;

  @media (max-width: 700px) {
    margin: 10px 10px 10px 10px;
  }
`;

const Error = styled.div`
  padding: 15px;
  font-weight: bold;
  color: ${props => props.theme.error};
`;

class Auth extends Component {
  state = {
    email: '',
    password: '',
    isSigningUp: false,
    isShownPasswordResetModal: false,
  };

  componentWillUnmount() {
    if (this.props.idToken) {
      if (timestamping) {
        this.props.onAuthSetTimestamp(this.props.idToken, {
          timestamp: `${version} ${new Date().toLocaleString()}`,
        });
      } else {
        this.props.onAuthFetchTimestamp(this.props.idToken);
      }
    }
  }

  submitHandler = event => {
    event.preventDefault();
    this.props.onAuth(
      this.state.email,
      this.state.password,
      this.state.isSigningUp
    );
  };

  emailInputChangeHandler = event => {
    this.setState({
      email: event.target.value,
    });
  };

  passwordInputChangeHandler = event => {
    this.setState({
      password: event.target.value,
    });
  };

  authModeSwitchHandler = () => {
    this.setState(prevState => ({ isSigningUp: !prevState.isSigningUp }));
  };

  showPasswordResetModalHandler = () => {
    this.props.onResetAuthError();

    this.setState({
      isShownPasswordResetModal: true,
    });
  };

  hidePasswordResetModalHandler = () => {
    this.setState({
      isShownPasswordResetModal: false,
    });
    this.props.onResetAuthError();
  };

  passwordResetHandler = email => {
    this.props.onPasswordReset(email);
  };

  render() {
    const submitButtonCaption = this.state.isSigningUp
      ? 'Registrácia'
      : 'Prihlásenie';

    const switchAuthModeCaption = this.state.isSigningUp
      ? 'Prihlásenie'
      : 'Registrácia';

    let error = null;
    if (this.props.authError) {
      error = <Error>Chyba: {this.props.authError.message}</Error>;
    }

    let form = (
      <form onSubmit={this.submitHandler}>
        <Input
          type="email"
          placeholder="e-mailová adresa"
          value={this.state.email}
          inputChanged={this.emailInputChangeHandler}
        />
        <Input
          type="password"
          placeholder="heslo"
          value={this.state.password}
          inputChanged={this.passwordInputChangeHandler}
        />
        <Button visible btnType="plain">
          {submitButtonCaption}
        </Button>
      </form>
    );

    let switchAuthModeButton = (
      <Button visible btnType="warning" clicked={this.authModeSwitchHandler}>
        Prepnúť na: {switchAuthModeCaption}
      </Button>
    );

    if (this.props.loading) {
      form = <Spinner />;
      switchAuthModeButton = null;
    }

    const passwordResetButton = (
      <Button
        clicked={this.showPasswordResetModalHandler}
        visible
        btnType="plain"
      >
        Zabudnuté heslo?
      </Button>
    );

    const backToLoginButton = (
      <Button
        clicked={this.hidePasswordResetModalHandler}
        visible
        btnType="plain"
      >
        Späť na prihlásenie
      </Button>
    );

    const passwordResetModal = !this.props.loading ? (
      <StyledPasswordResetModal>
        {!this.props.passwordResetSuccess ? (
          <>
            {error}
            <div>
              Skontrolujte alebo vypíšte prihlasovaciu e-mailovú adresu:
            </div>
            <Input
              type="email"
              placeholder="e-mailová adresa"
              value={this.state.email}
              inputChanged={this.emailInputChangeHandler}
            />
            <Button
              clicked={() => this.passwordResetHandler(this.state.email)}
              visible
              btnType="plain"
            >
              Vytvoriť nové heslo
            </Button>
            {backToLoginButton}
          </>
        ) : (
          <>
            {`Na e-mail ${this.state.email} bude zaslaný odkaz na vytvorenie nového hesla.`}
            {backToLoginButton}
          </>
        )}
      </StyledPasswordResetModal>
    ) : (
      <Spinner />
    );

    if (this.props.isAuthenticated) {
      return <Redirect to="/list-editor" />;
    }

    if (this.props.loading) {
      return <Spinner />;
    }

    if (!this.state.isShownPasswordResetModal) {
      return (
        <div className="Auth">
          {error}
          {form}
          {switchAuthModeButton}
          {passwordResetButton}
        </div>
      );
    }

    return passwordResetModal;
  }
}

const mapStateToProps = state => ({
  loading: state.auth.loading,
  authError: state.auth.authError,
  idToken: state.auth.idToken,
  isAuthenticated: state.auth.idToken !== null,
  passwordResetSuccess: state.auth.passwordResetSuccess,
});

const mapDispatchToProps = dispatch => ({
  onAuth: (email, password, isSigningUp) =>
    dispatch(actions.auth(email, password, isSigningUp)),
  onPasswordReset: email => dispatch(actions.passwordReset(email)),
  onAuthSetTimestamp: (idToken, timestamp) =>
    dispatch(actions.setTimestamp(idToken, timestamp)),
  onAuthFetchTimestamp: idToken => dispatch(actions.fetchTimestamp(idToken)),
  onResetAuthError: error => dispatch(actions.resetAuthError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
