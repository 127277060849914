import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Auth from './containers/Auth/Auth';
import ListEditor from './containers/ListEditor/ListEditor';
import Layout from './hoc/Layout/Layout';
import Logout from './containers/Auth/Logout/Logout';
import Home from './containers/Home/Home';
import Footer from './components/Footer/Footer';
import './App.css';

class App extends Component {
  render() {
    const { isAuthenticated, timestamp } = this.props;

    let routes = (
      <Switch>
        <Route path="/login" component={Auth} />
        <Route path="/" exact component={Home} />
        <Redirect to="/" />
      </Switch>
    );

    if (isAuthenticated) {
      routes = (
        <Switch>
          <Route path="/login" component={Auth} />
          <Route path="/logout" component={Logout} />
          <Route path="/list-editor" component={ListEditor} />
          <Route path="/" exact component={Home} />
        </Switch>
      );
    }

    return (
      <div className="App">
        <Layout isAuthenticated={isAuthenticated}>
          {routes}
          <Footer timestamp={timestamp} />
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.idToken !== null,
  timestamp: state.auth.timestamp,
});

export default withRouter(connect(mapStateToProps, null)(App));
