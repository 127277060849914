import React from 'react';
import styled from 'styled-components';

const Backdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);

  @media (min-width: 500px) {
    display: none;
  }
`;

const backdrop = props => <Backdrop onClick={props.clicked} />;

export default backdrop;
