import axios from 'axios';
import * as actionTypes from './actionTypes';
import { mockMode } from '../../configuration/configuration';
import axiosInstance from '../../axios-list-items';
import { mockData } from '../../configuration/mockData';

// action creators
export const authStart = () => ({
  type: actionTypes.AUTH_START,
});

export const authSuccess = (idToken, localId) => ({
  type: actionTypes.AUTH_SUCCESS,
  idToken,
  userId: localId,
});

export const authFail = error => ({
  type: actionTypes.AUTH_FAIL,
  error,
});

export const checkAuthTimeout = expirationTime => dispatch => {
  setTimeout(() => {
    dispatch(logout());
  }, expirationTime);
};

export const logout = () => {
  // TODO - the localStorage is probably not used at all yet
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('userId');
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

// async call with thunk middleware
// middleware sits between dispatching an action and reaching the reducer
// allows to return a function that will eventually dispatch an action
export const auth = (email, password, isSigningUp) => dispatch => {
  dispatch(authStart());

  if (mockMode) {
    dispatch(authSuccess('mockIdToken', 'mockLocalId'));
  } else {
    const authData = {
      email,
      password,
      returnSecureToken: true,
    };
    let url =
      'https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=AIzaSyBmxMDgisiim-Um3FzqYb31F1zszryWSyw';
    if (!isSigningUp) {
      url =
        'https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=AIzaSyBmxMDgisiim-Um3FzqYb31F1zszryWSyw';
    }
    axios
      .post(url, authData)
      .then(response => {
        dispatch(authSuccess(response.data.idToken, response.data.localId));
        dispatch(checkAuthTimeout(response.data.expiresIn * 1000));
      })
      .catch(err => {
        console.error(err);
        dispatch(authFail(err.response.data.error));
      });
  }
};

export const passwordResetStart = () => ({
  type: actionTypes.PASSWORD_RESET_START,
});

export const passwordResetSuccess = () => ({
  type: actionTypes.PASSWORD_RESET_SUCCESS,
});

export const passwordResetFail = error => ({
  type: actionTypes.PASSWORD_RESET_FAIL,
  payload: error,
});

export const passwordReset = email => dispatch => {
  dispatch(passwordResetStart());

  const reqBodyPayload = {
    requestType: 'PASSWORD_RESET',
    email,
  };
  const url =
    'https://www.googleapis.com/identitytoolkit/v3/relyingparty/getOobConfirmationCode?key=AIzaSyBmxMDgisiim-Um3FzqYb31F1zszryWSyw';
  axios
    .post(url, reqBodyPayload)
    .then(response => {
      // console.log('passwordReset success', response);
      dispatch(passwordResetSuccess());
    })
    .catch(err => {
      console.log('passwordReset failed ', err.response.data.error);
      dispatch(passwordResetFail(err.response.data.error));
    });
};

const setTimestampSuccess = timestamp => ({
  type: actionTypes.SET_TIMESTAMP_SUCCESS,
  payload: timestamp,
});

const setTimestampFail = error => ({
  type: actionTypes.SET_TIMESTAMP_FAIL,
  payload: error,
});

export const setTimestamp = (idToken, timestamp) => dispatch => {
  axiosInstance
    .post(`timestamps.json?auth=${idToken}`, timestamp)
    .then(response => {
      // console.log('actions - timestamp success ', response.data)
      dispatch(setTimestampSuccess(timestamp));
    })
    .catch(err => {
      console.error('actions - timestamp error', err);
      dispatch(setTimestampFail(err));
    });
};

const fetchTimestampSuccess = timestamp => ({
  type: actionTypes.FETCH_TIMESTAMP_SUCCESS,
  payload: timestamp,
});

const fetchTimestampFail = error => ({
  type: actionTypes.FETCH_TIMESTAMP_FAIL,
  payload: error,
});

export const fetchTimestamp = idToken => dispatch => {
  if (mockMode) {
    // console.log("in fetchTimestamp mockData.mockTimestamps ", mockData.mockTimestamp.mockTimestampKey.timestamp)
    dispatch(
      fetchTimestampSuccess(mockData.mockTimestamp.mockTimestampKey.timestamp)
    );
  } else {
    axiosInstance
      .get(`timestamps.json?auth=${idToken}&orderBy="$value"&limitToLast=1`)
      .then(response => {
        // console.log(
        //   'actions, fetchTimestamp',
        //   Object.values(response.data)[0].timestamp
        // );
        dispatch(
          fetchTimestampSuccess(Object.values(response.data)[0].timestamp)
        );
      })
      .catch(err => {
        console.error('actions, error - fetchTimestamp', err);
        dispatch(fetchTimestampFail(err));
      });
  }
};

export const resetAuthError = () => dispatch =>
  dispatch({
    type: actionTypes.SET_AUTH_ERROR,
  });
