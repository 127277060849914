import * as actionTypes from '../actions/actionTypes';

const authStart = state => ({
  ...state,
  authError: null,
  loading: true,
});

const authSuccess = (state, action) => ({
  ...state,
  idToken: action.idToken,
  userId: action.userId,
  authError: null,
  loading: false,
});

const authFail = (state, action) => ({
  ...state,
  authError: action.error,
  loading: false,
});

const logout = state => ({
  ...state,
  idToken: null,
  userId: null,
  lists: null,
  listItems: null,
  passwordResetSuccess: false,
});

const passwordResetStart = state => ({
  ...state,
  authError: null,
  loading: true,
  passwordResetSuccess: false,
});

const passwordResetSuccess = state => ({
  ...state,
  loading: false,
  authError: null,
  passwordResetSuccess: true,
});

const passwordResetFail = (state, action) => ({
  ...state,
  loading: false,
  authError: action.payload,
  passwordResetSuccess: false,
});

const setTimestampSuccess = (state, action) => ({
  ...state,
  authError: null,
  timestamp: action.payload.timestamp,
});

const setTimestampFail = (state, action) => ({
  ...state,
  authError: action.payload,
});

const fetchTimestampSuccess = (state, action) => ({
  ...state,
  authError: null,
  timestamp: action.payload,
});

const fetchTimestampFail = (state, action) => ({
  ...state,
  authError: action.error,
});

const resetAuthError = state => ({
  ...state,
  authError: null,
});

const initialState = {
  idToken: null,
  userId: null,
  authError: null,
  loading: false,
  timestamp: '',
  passwordResetSuccess: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return logout(state);
    case actionTypes.SET_AUTH_ERROR:
      return resetAuthError(state);
    case actionTypes.PASSWORD_RESET_START:
      return passwordResetStart(state);
    case actionTypes.PASSWORD_RESET_SUCCESS:
      return passwordResetSuccess(state, action);
    case actionTypes.PASSWORD_RESET_FAIL:
      return passwordResetFail(state, action);
    case actionTypes.SET_TIMESTAMP_SUCCESS:
      return setTimestampSuccess(state, action);
    case actionTypes.SET_TIMESTAMP_FAIL:
      return setTimestampFail(state, action);
    case actionTypes.FETCH_TIMESTAMP_SUCCESS:
      return fetchTimestampSuccess(state, action);
    case actionTypes.FETCH_TIMESTAMP_FAIL:
      return fetchTimestampFail(state, action);
    default:
      return state;
  }
};

export default reducer;
