// displays a list of users lists
import React from 'react';
import styled from 'styled-components';
import Spinner from '../../UI/Spinner/Spinner';
import Button from '../../UI/Button/Button';
import DeleteModal from '../../DeleteModal/DeleteModal';
import updateListItemIcon from '../../../assets/updateListItemIcon.png';
import addListButtonIcon from '../../../assets/addListButtonIcon.png';
import IconButton from '../../UI/IconButton/IconButton';
import deleteIcon from '../../../assets/delete.png';
import IconButtonRow from '../../UI/IconButtonRow/IconButtonRow';

const ListOfListsTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 10px;
`;

const ListOfListsCard = styled.div`
  background-color: ${props => props.theme.lightGray};
  margin: 10px auto;
  text-align: left;
`;

const ListOfListsRow = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-top: 3px solid ${props => props.theme.mainWhite};
`;

const ListOfListsName = styled.div`
  color: ${props => props.theme.mainGray};
  width: 80%;
  padding: 10px 0 10px 10px;
  min-height: 20px;
  :hover {
    font-weight: bold;
  }
`;

const CurrentListName = styled(ListOfListsName)`{
color: ${props => props.theme.mainDark};
font-weight: bold;
`;

const ListOfLists = ({
  lists,
  currentListName,
  clicked,
  loadingLists,
  onDelete,
  isDeleteListModalVisible,
  isListModalVisible,
  onCreateList,
  onUpdateList,
  confirmDelete,
  cancelDelete,
}) => (
  <>
    {loadingLists ? (
      <Spinner />
    ) : lists && Object.keys(lists).length !== 0 ? (
      <>
        <DeleteModal
          visible={isDeleteListModalVisible}
          confirm={confirmDelete}
          cancel={cancelDelete}
          listDeleting={currentListName}
        />
        <ListOfListsCard>
          <ListOfListsTitle>
            Moje zoznamy
            <IconButton
              clicked={onCreateList}
              icon={addListButtonIcon}
              altName="Vytvoriť nový zoznam"
            />
          </ListOfListsTitle>
          {Object.entries(lists).map(list => {
            const [listId, listNameAndUserId] = list;
            const isCurrentList =
              currentListName === listNameAndUserId.listName;

            const listNameOnClickProp = {
              onClick: () => clicked(list, listId, listNameAndUserId.listName),
            };
            const listName = `${listNameAndUserId.listName} `;

            return (
              <ListOfListsRow key={listId}>
                {isCurrentList ? (
                  <>
                    <CurrentListName {...listNameOnClickProp}>
                      {listName}
                    </CurrentListName>
                    <IconButtonRow>
                      <IconButton
                        icon={updateListItemIcon}
                        altName="Upraviť názov zoznamu"
                        clicked={
                          isCurrentList ? () => onUpdateList(list[0]) : null
                        }
                      />
                      <IconButton
                        icon={deleteIcon}
                        altName="Zmazať zoznam"
                        clicked={() => onDelete(list)}
                      />
                    </IconButtonRow>
                  </>
                ) : (
                  <ListOfListsName {...listNameOnClickProp}>
                    {listName}
                  </ListOfListsName>
                )}
              </ListOfListsRow>
            );
          })}
        </ListOfListsCard>
      </>
    ) : isListModalVisible || loadingLists ? null : (
      <div>
        <br />
        Nemáte žiadne zoznamy
        <br />
        <Button visible clicked={onCreateList}>
          Vytvoriť nový zoznam
        </Button>
      </div>
    )}
  </>
);

export default ListOfLists;
