// displays a listOfItems of shopping items
import React from 'react';
import './ListOfItems.css';
import Spinner from '../../UI/Spinner/Spinner';
import ListRow from '../ListRow/ListRow';
import InitialEmptyList from '../InitialEmptyList/InitialEmptyList';
import DeleteModal from '../../DeleteModal/DeleteModal';
import Button from '../../UI/Button/Button';

const ListOfItems = ({
  loadingListItems,
  currentListName,
  listItems,
  clicked,
  markdone,
  onDelete,
  isDeleteModalVisible,
  listItemDeletingKey,
  confirmDelete,
  cancelDelete,
  isCreateListItemButtonHidden,
  onCreateButtonClick,
  isListModalVisible,
}) => {
  const createListItemButton = (
    <Button
      visible={!isCreateListItemButtonHidden}
      clicked={onCreateButtonClick}
    >
      Pridať novú položku do zoznamu
    </Button>
  );

  const getContent = () => {
    if (loadingListItems) {
      return <Spinner />;
    }
    if (currentListName && !isListModalVisible) {
      return getContentComponents();
    }
    return null;
  };

  const getContentComponents = () => {
    if (listItems && Object.keys(listItems).length !== 0) {
      return (
        <div>
          <DeleteModal
            visible={isDeleteModalVisible}
            confirm={confirmDelete}
            cancel={cancelDelete}
            listItemDeleting={listItems[listItemDeletingKey]}
          />
          {createListItemButton}
          {Object.entries(listItems).map(item => (
            <div key={item[0]}>
              <ListRow
                itemKey={item[0]}
                clicked={() => clicked(item[0])}
                content={item[1].listItemText}
                done={item[1].listItemDone}
                onDelete={onDelete}
                markdone={markdone}
              />
            </div>
          ))}
        </div>
      );
    }

    if (loadingListItems) return null;

    return (
      <div className="ListOfItemsEmptyMessage">
        {`Zoznam ${currentListName} je prázdny `}
        {createListItemButton}
        <InitialEmptyList />
      </div>
    );
  };

  return <div className="ListOfItems">{getContent()}</div>;
};

export default ListOfItems;
