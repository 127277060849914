export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';

export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';

export const PASSWORD_RESET_START = 'PASSWORD_RESET_START';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';

export const CREATE_LIST_ITEM_START = 'SUBMIT_LIST_ITEM_START';
export const CREATE_LIST_ITEM_SUCCESS = 'SUBMIT_LIST_ITEM_SUCCESS';
export const CREATE_LIST_ITEM_FAIL = 'SUBMIT_LIST_ITEM_FAIL';

export const FETCH_LIST_ITEMS_START = 'FETCH_LIST_ITEMS_START';
export const FETCH_LIST_ITEMS_SUCCESS = 'FETCH_LIST_ITEMS_SUCCESS';
export const FETCH_LIST_ITEMS_FAIL = 'FETCH_LIST_ITEMS_FAIL';

export const UPDATE_LIST_ITEM_START = 'UPDATE_LIST_ITEM_START';
export const UPDATE_LIST_ITEM_SUCCESS = 'UPDATE_LIST_ITEM_SUCCESS';
export const UPDATE_LIST_ITEM_FAIL = 'UPDATE_LIST_ITEM_FAIL';

export const DELETE_LIST_ITEM_START = 'DELETE_LIST_ITEM_START';
export const DELETE_LIST_ITEM_SUCCESS = 'DELETE_LIST_ITEM_SUCCESS';
export const DELETE_LIST_ITEM_FAIL = 'DELETE_LIST_ITEM_FAIL';

export const FETCH_LIST_NAMES_FAIL = 'FETCH_LIST_NAMES_FAIL';
export const FETCH_LISTS_SUCCESS = 'FETCH_LISTS_SUCCESS';
export const FETCH_LIST_NAMES_START = 'FETCH_LIST_NAMES_START';

export const CREATE_LIST_START = 'CREATE_LIST_START';
export const CREATE_LIST_SUCCESS = 'CREATE_LIST_SUCCESS';
export const CREATE_LIST_FAIL = 'CREATE_LIST_FAIL';

export const UPDATE_LIST_START = 'UPDATE_LIST_START';
export const UPDATE_LIST_SUCCESS = 'UPDATE_LIST_SUCCESS';
export const UPDATE_LIST_FAIL = 'UPDATE_LIST_FAIL';

export const DELETE_LIST_FAIL = 'DELETE_LIST_FAIL';
export const DELETE_LIST_SUCCESS = 'DELETE_LIST_SUCCESS';
export const DELETE_LIST_START = 'DELETE_LIST_START';

export const SET_CURRENT_LIST = 'SET_CURRENT_LIST';
export const SET_ERROR = 'SET_ERROR';

export const SET_TIMESTAMP_SUCCESS = 'SET_TIMESTAMP_SUCCESS';
export const SET_TIMESTAMP_FAIL = 'SET_TIMESTAMP_FAIL';

export const FETCH_TIMESTAMP_FAIL = 'FETCH_TIMESTAMP_FAIL';
export const FETCH_TIMESTAMP_SUCCESS = 'FETCH_TIMESTAMP_SUCCESS';
