import React from 'react';
import './ListEditorModal.css';

const ListEditorModal = ({ modalTitle, children }) => (
  <div className="container">
    <div className="modal">
      <div className="modal-inner">
        <div className="modal-content">{children}</div>
      </div>
    </div>
  </div>
);

export default ListEditorModal;
