import React from 'react';
import { NavLink } from 'react-router-dom';
import './AuthMessage.css';

const authMessage = () => {
  const login = 'Prihlásenie';

  return (
    <div>
      <br />
      <NavLink className="AuthMessageSubtitle" to="/login">
        Pre prístup k zoznamom
      </NavLink>

      <br />
      <br />
      <NavLink className="AuthMessageTitle" to="/login">
        {login}
      </NavLink>
    </div>
  );
};

export default authMessage;
