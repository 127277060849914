import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { fetchTimestamp } from '../../services/fetchTimestampService';

const AutoRefreshingRouter = ({ idToken, children }) => {
  let fetchedTimestamp = null;
  if (idToken) {
    fetchTimestamp(idToken)
      .then(response => {
        fetchedTimestamp = Object.values(response.data)[0].timestamp;
      })
      .catch(err => {
        console.error('Error fetching timestamp:', err);
      });
  }

  useEffect(() => {
    setInterval(() => {
      if (idToken) {
        fetchTimestamp(idToken)
          .then(response => {
            const newFetchedTimestamp = Object.values(response.data)[0]
              .timestamp;

            if (newFetchedTimestamp !== fetchedTimestamp) {
              window.location.reload();
            }
          })
          .catch(err => {
            console.error('Error fetching timestamp:', err);
          });
      }
    }, 1000 * 60 * 60 * 6);
  }, [fetchedTimestamp, idToken]);

  return <BrowserRouter>{children}</BrowserRouter>;
};

const mapStateToProps = state => ({
  idToken: state.auth.idToken,
});

export default connect(mapStateToProps, null)(AutoRefreshingRouter);
