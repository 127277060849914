import * as actionTypes from '../actions/actionTypes';
import { removeDeletedList, removeDeletedListItem } from '../storeUtility';

const createListItemStart = state => ({
  ...state,
  error: null,
  loadingListItems: true,
});

const createListItemSuccess = (state, action) => ({
  ...state,
  error: null,
  loadingListItems: false,
  listItems: { ...state.listItems, ...action.payload },
});

const createListItemFail = (state, action) => ({
  ...state,
  loadingListItems: false,
  error: action.payload,
});

const fetchListItemsStart = state => ({
  ...state,
  error: null,
  loadingListItems: true,
});

const fetchListItemsSuccess = (state, action) => ({
  ...state,
  error: null,
  listItems: action.payload,
  loadingListItems: false,
});

const fetchListItemsFail = (state, action) => ({
  ...state,
  loadingListItems: false,
  error: action.payload,
});

const updateListItemStart = state => ({
  ...state,
  error: null,
  loadingListItems: true,
});

const updateListItemSuccess = (state, action) => ({
  ...state,
  error: null,
  loadingListItems: false,
  listItems: { ...state.listItems, ...action.payload },
});

const updateListItemFail = (state, action) => ({
  ...state,
  loadingListItems: false,
  error: action.payload,
});

const deleteListItemStart = state => ({
  ...state,
  error: null,
  loadingListItems: true,
});

const deleteListItemSuccess = (state, action) => ({
  ...state,
  error: null,
  loadingListItems: false,
  listItems: removeDeletedListItem(state, action),
});

const deleteListItemFail = (state, action) => ({
  ...state,
  loadingListItems: false,
  error: action.payload,
});

const fetchListNamesStart = state => ({
  ...state,
  error: null,
  loadingLists: true,
});

const fetchListsSuccess = (state, action) =>
  // console.log("fetchListsSuccess state before change ", state)
  // console.log("fetchListsSuccess  action ", action)
  ({
    ...state,
    error: null,
    lists: action.payload,
    loadingLists: false,
  });
const fetchListNamesFail = (state, action) => ({
  ...state,
  loadingLists: false,
  error: action.payload,
});

const setCurrentList = (state, action) => ({
  ...state,
  currentListName: action.payload.listName,
  currentList: action.payload.list,
});

const createListStart = state => ({
  ...state,
  error: null,
  loadingLists: true,
});

const createListSuccess = (state, action) => ({
  ...state,
  error: null,
  isListModalVisible: false,
  loadingLists: false,
  currentListName: action.payload[Object.keys(action.payload)].listName,
  lists: { ...state.lists, ...action.payload },
  listItems: null,
});

const createListFail = (state, action) => ({
  ...state,
  loadingLists: false,
  error: action.payload,
});

const updateListStart = state => ({
  ...state,
  error: null,
  loadingLists: true,
});

const updateListSuccess = (state, action) => ({
  ...state,
  error: null,
  isListModalVisible: false,
  loadingLists: false,
  currentListName: action.payload[Object.keys(action.payload)].listName,
  lists: { ...state.lists, ...action.payload },
});

const updateListFail = (state, action) => ({
  ...state,
  loadingLists: false,
  error: action.payload,
});

const deleteListStart = state => ({
  ...state,
  error: null,
  loadingLists: true,
});

const deleteListSuccess = (state, action) => ({
  ...state,
  error: null,
  loadingLists: false,
  currentListName: null,
  lists: removeDeletedList(state, action),
});

const deleteListFail = (state, action) => ({
  ...state,
  loadingLists: false,
  error: action.payload,
});

const setError = (state, action) => ({
  ...state,
  error: action.payload,
});

const initialState = {
  lists: null,
  currentList: null,
  currentListName: null,
  loadingLists: false,
  listItems: null,
  loadingListItems: false,
  error: null,
  isListModalVisible: null,
  listModalAction: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_LIST_ITEM_START:
      return createListItemStart(state);
    case actionTypes.CREATE_LIST_ITEM_SUCCESS:
      return createListItemSuccess(state, action);
    case actionTypes.CREATE_LIST_ITEM_FAIL:
      return createListItemFail(state, action);
    case actionTypes.FETCH_LIST_ITEMS_START:
      return fetchListItemsStart(state);
    case actionTypes.FETCH_LIST_ITEMS_SUCCESS:
      return fetchListItemsSuccess(state, action);
    case actionTypes.FETCH_LIST_ITEMS_FAIL:
      return fetchListItemsFail(state, action);
    case actionTypes.UPDATE_LIST_ITEM_START:
      return updateListItemStart(state);
    case actionTypes.UPDATE_LIST_ITEM_SUCCESS:
      return updateListItemSuccess(state, action);
    case actionTypes.UPDATE_LIST_ITEM_FAIL:
      return updateListItemFail(state, action);
    case actionTypes.DELETE_LIST_ITEM_START:
      return deleteListItemStart(state);
    case actionTypes.DELETE_LIST_ITEM_SUCCESS:
      return deleteListItemSuccess(state, action);
    case actionTypes.DELETE_LIST_ITEM_FAIL:
      return deleteListItemFail(state, action);
    case actionTypes.FETCH_LIST_NAMES_START:
      return fetchListNamesStart(state);
    case actionTypes.FETCH_LISTS_SUCCESS:
      return fetchListsSuccess(state, action);
    case actionTypes.FETCH_LIST_NAMES_FAIL:
      return fetchListNamesFail(state, action);
    case actionTypes.SET_CURRENT_LIST:
      return setCurrentList(state, action);
    case actionTypes.CREATE_LIST_START:
      return createListStart(state, action);
    case actionTypes.CREATE_LIST_SUCCESS:
      return createListSuccess(state, action);
    case actionTypes.CREATE_LIST_FAIL:
      return createListFail(state, action);
    case actionTypes.UPDATE_LIST_START:
      return updateListStart(state, action);
    case actionTypes.UPDATE_LIST_SUCCESS:
      return updateListSuccess(state, action);
    case actionTypes.UPDATE_LIST_FAIL:
      return updateListFail(state, action);
    case actionTypes.DELETE_LIST_START:
      return deleteListStart(state, action);
    case actionTypes.DELETE_LIST_SUCCESS:
      return deleteListSuccess(state, action);
    case actionTypes.DELETE_LIST_FAIL:
      return deleteListFail(state, action);
    case actionTypes.SET_ERROR:
      return setError(state, action);
    default:
      return state;
  }
};

export default reducer;
