import React from 'react';
import styled from 'styled-components';
import NavigationItems from '../NavigationItems';
import Backdrop from '../../UI/Backdrop/Backdrop';

const StyledSideMenu = styled.div`
  position: fixed;
  max-width: 70%;
  min-width: 150px;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: ${props => props.theme.mainWhite};
  padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;
  box-shadow: 10px 5px 5px olive;

  @media (min-width: 500px) {
    display: none;
  }
`;

const sideMenu = props => {
  const { isAuthenticated, close } = props;
  return (
    <>
      <Backdrop clicked={close} />
      <StyledSideMenu>
        <NavigationItems close={close} isAuthenticated={isAuthenticated} />
      </StyledSideMenu>
    </>
  );
};

export default sideMenu;
