const removeDeleted = (currentArray, keyField) =>
  currentArray.reduce((acc, item) => {
    if (item[0] !== keyField) {
      acc[item[0]] = item[1];
    }
    return acc;
  }, {});

export const removeDeletedListItem = (state, action) => {
  const deletedListItemKey = action.payload;
  const newListItemsArray = Object.entries(state.listItems);

  return removeDeleted(newListItemsArray, deletedListItemKey);
};

export const removeDeletedList = (state, action) => {
  const deletedListKey = action.payload;
  const newListsArray = Object.entries(state.lists);

  return removeDeleted(newListsArray, deletedListKey);
};

export const filterByList = (mockListItems, listId) =>
  Object.entries(mockListItems)
    .filter(mockListItem => mockListItem[1].listId === listId)
    .reduce((acc, item) => {
      acc[item[0]] = item[1];
      return acc;
    }, {});
