import React from 'react';
import './Input.css';

const input = props => (
  <div className="Input">
    <input
      type={props.type}
      placeholder={props.placeholder}
      className="InputElement"
      value={props.value}
      onChange={props.inputChanged}
    />
  </div>
);

export default input;
