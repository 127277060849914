import React from 'react';
import ListItem from '../ListItem/ListItem';
import IconButtonRow from '../../UI/IconButtonRow/IconButtonRow';
import './ListRow.css';
import doneIcon from '../../../assets/done.png';
import deleteIcon from '../../../assets/delete.png';
import IconButton from '../../UI/IconButton/IconButton';

const listRow = props => {
  const { clicked, content, done, markdone, onDelete, itemKey } = props;

  return (
    <div className="ListRow">
      <ListItem content={content} done={done} clicked={clicked} />
      {itemKey ? (
        <IconButtonRow
          markdone={markdone}
          onDelete={onDelete}
          itemKey={itemKey}
        >
          <IconButton
            icon={doneIcon}
            altName="mark list item as done"
            clicked={() => props.markdone(props.itemKey)}
          />
          <IconButton
            icon={deleteIcon}
            altName="delete list item"
            clicked={() => props.onDelete(props.itemKey)}
          />
        </IconButtonRow>
      ) : null}
    </div>
  );
};

export default listRow;
