import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const NavigationItemLi = styled.li`
  //border: cadetblue solid 1px;

  a {
    text-decoration: none;
    box-sizing: border-box;
    color: ${props => props.theme.mainGray};
    display: block;
    //background: var(--lightBlue);
  }

  a:hover {
    color: ${props => props.theme.hoverGray};
    font-weight: bold;
  }

  a:active,
  a.active {
    color: ${props => props.theme.mainGray};
    font-weight: bold;
  }

  img {
    max-height: 6vmin;
    min-height: 40px;
    align-self: center;
    justify-self: center;
  }

  figure {
    display: grid;
    margin: 0;
  }

  figcaption {
    align-self: center;
    justify-self: center;
  }
`;

const navigationItem = props => {
  const item = (
    <NavigationItemLi>
      {props.link ? (
        <NavLink
          onClick={props.clicked}
          to={props.link}
          activeClassName="active"
          exact
        >
          <figure>
            <img src={props.icon} alt={props.altText} />
            {props.caption ? <figcaption>{props.caption}</figcaption> : null}
          </figure>
        </NavLink>
      ) : (
        <figure onClick={props.clicked}>
          <img src={props.icon} alt={props.altText} />
          {props.caption ? <figcaption>{props.caption}</figcaption> : null}
        </figure>
      )}
    </NavigationItemLi>
  );

  return item;
};

export default navigationItem;
