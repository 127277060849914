import axiosInstance from '../axios-list-items';
import { mockMode } from '../configuration/configuration';
import { mockData } from '../configuration/mockData';

export const fetchTimestamp = async idToken => {
  if (mockMode) {
    return new Promise((resolve, reject) =>
      resolve({ data: mockData.mockTimestamp })
    );
  }

  return axiosInstance.get(
    `timestamps.json?auth=${idToken}&orderBy="$value"&limitToLast=1`
  );
};
