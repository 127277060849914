import React from 'react';
import styled from 'styled-components';
import logo from '../../assets/logo.png';
import listEditorIcon from '../../assets/listEditorIcon.png';
import logoutIcon from '../../assets/logoutIcon.png';
import loginIcon from '../../assets/loginIcon.png';
import NavigationItem from './NavigationItem/NavigationItem';

const NavigationItemsUl = styled.ul`
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(129px, 1fr));
  list-style-type: none;
  @media (max-width: 499px) {
    grid-gap: 25px;
    grid-template-rows: repeat(auto-fit);
  }
`;

const navigationItems = ({ isAuthenticated, close }) => {
  const listEditorNavItem = isAuthenticated ? (
    <NavigationItem
      clicked={close}
      link="/list-editor"
      icon={listEditorIcon}
      altText="Čo nakúpiť - Moje zoznamy"
      caption="Moje zoznamy"
    />
  ) : null;

  const loginLogoutNavItem = isAuthenticated ? (
    <NavigationItem
      clicked={close}
      link="/logout"
      icon={logoutIcon}
      altText="Čo nakúpiť - Odhlásiť"
      caption="Odhlásiť"
    />
  ) : (
    <NavigationItem
      clicked={close}
      link="/login"
      icon={loginIcon}
      altText="Čo nakúpiť - Prihlásiť"
      caption="Prihlásiť"
    />
  );

  return (
    <NavigationItemsUl>
      <NavigationItem
        exact
        clicked={close}
        link="/"
        icon={logo}
        altText="Čo nakúpiť - Domov"
        caption="Domov"
      />
      {listEditorNavItem}
      {loginLogoutNavItem}
    </NavigationItemsUl>
  );
};

export default navigationItems;
