import React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../UI/Button/Button';

const ListEditorFormTextarea = styled.textarea`
  min-width: 99%;
  min-height: 100px;
  outline: none;
  resize: none;
  border: 1px solid grey;
`;

const ListEditorForm = ({
  listItemText,
  listItemUpdating,
  onSubmit,
  updating,
  onChange,
  submitButtonCaption,
  onCancel,
}) => (
  <form onSubmit={onSubmit}>
    <ListEditorFormTextarea
      name=""
      id=""
      value={updating ? listItemUpdating.listItemText : listItemText}
      onChange={onChange}
    />
    <br />
    <Button visible>{submitButtonCaption}</Button>
    <Button visible clicked={onCancel}>
      Zrušiť
    </Button>
  </form>
);

ListEditorForm.propTypes = {
  onSubmit: PropTypes.func,
  updating: PropTypes.bool,
  listItemUpdating: PropTypes.object,
  listItemText: PropTypes.string,
  onChange: PropTypes.func,
  submitButtonCaption: PropTypes.string,
};

export default ListEditorForm;
