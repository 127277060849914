import React from 'react';
import * as PropTypes from 'prop-types';
import './ListItem.css';

const listItem = ({ done, clicked, content }) => {
  const style = done ? 'ListItem ListItemDone' : 'ListItem';

  return (
    <div className={style} onClick={clicked}>
      {content}
    </div>
  );
};

listItem.propTypes = {
  clicked: PropTypes.func,
};

export default listItem;
