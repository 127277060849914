import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ListOfItems from '../../components/Lists/ListOfItems/ListOfItems';
import * as actions from '../../store/actions/index';
import './ListEditor.css';
import ListOfLists from '../../components/Lists/ListOfLists/ListOfLists';
import ListEditorForm from '../../components/ListEditorForm/ListEditorForm';
import ListModal from '../../components/ListModal/ListModal';
import ListEditorModal from '../../components/UI/ListEditorModal/ListEditorModal';

/**
 * displays a list of items and an editor for items
 */
class ListEditor extends Component {
  state = {
    listItemText: '',
    listItemDone: false,

    currentListName: null,

    isCreatingListItem: false,

    listItemUpdating: null,
    listItemUpdatingKey: null,
    isUpdating: false,

    listItemDeletingKey: null,
    isDeleting: false,

    newListName: '',
    isListModalVisible: false,
    listModalAction: '',
    listUpdating: null,
    listUpdatingKey: '',

    listDeleting: null,
    isListDeleting: false,
    error: null,
  };

  componentDidMount() {
    const {
      isAuthenticated,
      lists,
      onInitFetchLists,
      idToken,
      userId,
    } = this.props;
    if (isAuthenticated && !lists) {
      onInitFetchLists(idToken, userId);
    }
  }

  listItemFormSubmitHandler = event => {
    event.preventDefault();

    if (
      this.state.isUpdating &&
      this.state.listItemUpdating.listItemText !== ''
    ) {
      // reconstructing the original object
      const listItemUpdating = {
        [this.state.listItemUpdatingKey]: {
          listItemText: this.state.listItemUpdating.listItemText,
          listItemDone: this.state.listItemUpdating.listItemDone,
          listId: this.props.currentList[0],
          userId: this.props.userId,
        },
      };
      this.props.onUpdateListItem(listItemUpdating, this.props.idToken);
      this.setState({
        listItemUpdating: null,
        listItemUpdatingKey: null,
        isUpdating: false,
        isListItemTextUpdating: false,
        isCreatingListItem: false,
      });
    } else if (this.state.listItemText !== '') {
      const listItem = {
        listItemText: this.state.listItemText,
        listItemDone: false,
        listId: this.props.currentList[0],
        userId: this.props.userId,
      };
      this.props.onCreateListItem(listItem, this.props.idToken);
      this.setState({
        listItemText: '',
        listItemDone: false,
        isCreatingListItem: false,
      });
    } else {
      this.setState({
        error: 'Položka zoznamu neobsahuje žiadny text',
      });
    }
  };

  textAreaInputChangeHandler = event => {
    if (this.state.isUpdating) {
      this.setState({
        listItemUpdating: {
          ...this.state.listItemUpdating,
          listItemText: event.target.value,
        },
      });
    } else {
      this.setState({
        listItemText: event.target.value,
      });
    }
  };

  createListItemHandler = () => {
    this.setState({
      isCreatingListItem: true,
      isListModalVisible: false,
      isListDeleting: false,
      isDeleting: false,
    });
  };

  cancelListItemEditingHandler = event => {
    event.preventDefault();
    this.setState({
      isCreatingListItem: false,
      listItemText: '',
      isListItemTextUpdating: false,
      listItemUpdating: null,
      listItemUpdatingKey: null,
      isUpdating: false,
      error: null,
    });
  };

  listItemClickHandler = key => {
    const { listItems } = this.props;
    const listItemUpdating = listItems[key];

    this.setState({
      listItemUpdating,
      listItemUpdatingKey: key,
      isListItemTextUpdating: true,
      isUpdating: true,
    });
  };

  listItemMarkDoneClickHandler = key => {
    const listItem = this.props.listItems[key];
    const newListItem = { ...listItem, listItemDone: !listItem.listItemDone };

    const listItemUpdating = { [key]: newListItem };
    this.props.onUpdateListItem(listItemUpdating, this.props.idToken);
    this.setState({
      isListModalVisible: false,
      isDeleting: false,
      isCreatingListItem: false,
      isListItemTextUpdating: false,
      error: null,
    });
  };

  listItemDeleteClickHandler = key => {
    this.setState({
      listItemDeletingKey: key,
      isDeleting: true,
      isCreatingListItem: false,
      isListModalVisible: false,
      isListDeleting: false,
      isListItemTextUpdating: false,
    });
    this.props.onError();
  };

  listItemDeleteConfirmedHandler = () => {
    this.props.onDeleteListItem(
      this.state.listItemDeletingKey,
      this.props.idToken
    );
    this.setState({
      listItemDeletingKey: null,
      isDeleting: false,
    });
  };

  listItemDeleteCanceledHandler = () => {
    this.setState({
      listItemDeletingKey: null,
      isDeleting: false,
    });
  };

  listCreateClickHandler = () => {
    this.setState({
      listUpdating: null,
      isDeleting: false,
      newListName: '',
      listUpdatingKey: '',
      isListDeleting: null,
      isListModalVisible: true,
      listModalAction: 'createList',
      isCreatingListItem: false,
    });
  };

  listCreateSubmitHandler = () => {
    const { newListName } = this.state;

    if (newListName === '') {
      this.props.onError('Zoznam musí mať názov');
    } else if (
      Object.values(this.props.lists).some(
        item => item.listName === newListName
      )
    ) {
      this.props.onError(`Zoznam s názvom ${newListName} už existuje`);
    } else {
      const newList = {
        listName: newListName,
        userId: this.props.userId,
      };
      this.props.onCreateList(newList, this.props.idToken);
      this.setState({
        newListName: '',
        isListModalVisible: false,
        listModalAction: '',
      });
    }
  };

  listUpdateClickHandler = key => {
    const listUpdating = this.props.lists[key];
    this.setState({
      listUpdating,
      newListName: listUpdating.listName,
      listUpdatingKey: key,
      isListDeleting: null,
      isListModalVisible: true,
      listModalAction: 'updateList',
      isCreatingListItem: false,
      isDeleting: false,
    });
  };

  listUpdateSubmitHandler = () => {
    const { newListName } = this.state;
    if (newListName === '') {
      this.props.onError('Zoznam musí mať názov');
    } else if (
      Object.values(this.props.lists).some(
        item => item.listName === newListName
      )
    ) {
      this.props.onError(`Zoznam s názvom ${newListName} už existuje`);
    } else {
      const updatedList = {
        [this.state.listUpdatingKey]: {
          ...this.state.listUpdating,
          listName: newListName,
        },
      };
      this.props.onUpdateList(updatedList, this.props.idToken);
      this.setState({
        listUpdating: null,
        listItemUpdatingKey: '',
        newListName: '',
        isListModalVisible: false,
      });
    }
  };

  listModalCancelHandler = () => {
    this.setState({
      newListName: '',
      listUpdating: null,
      listItemUpdatingKey: '',
      isListModalVisible: false,
      error: null,
    });
    this.props.onError();
  };

  listNameClickHandler = (list, listId, listName) => {
    this.setState({
      currentListName: listName,
      newListName: '',
      error: null,
      isCreatingListItem: false,
      listItemUpdating: null,
      isListModalVisible: false,
      isUpdating: false,
      isListItemTextUpdating: null,
    });
    this.props.onSetCurrentList(list, listName);
    this.props.onFetchListItems(listId, this.props.idToken, this.props.userId);
  };

  listDeleteClickHandler = list => {
    this.setState({
      listDeleting: list,
      isListDeleting: true,
      newListName: '',
      error: null,
      isCreatingListItem: false,
      listItemUpdating: null,
      isListModalVisible: false,
      isDeleting: false,
      isUpdating: false,
      isListItemTextUpdating: null,
    });
    this.props.onError();
  };

  listDeleteConfirmedHandler = () => {
    this.props.onDeleteList(this.state.listDeleting, this.props.idToken);
    this.setState({
      listDeleting: null,
      isListDeleting: false,
    });
  };

  listDeleteCanceledHandler = () => {
    this.setState({
      listDeletingKey: null,
      isListDeleting: false,
      error: null,
    });
  };

  listModalInputChangeHandler = event => {
    this.setState({
      newListName: event.target.value,
    });
  };

  render() {
    const submitButtonCaption = this.state.isUpdating ? 'Upraviť' : 'Pridať';

    let error = null;
    if (this.props.error) {
      error = <p className="Error">Chyba: {this.props.error}</p>;
    }

    if (this.state.error) {
      error = <p className="Error">Chyba: {this.state.error}</p>;
    }

    const listOfItems = (
      <ListOfItems
        currentListName={this.props.currentListName}
        listItems={this.props.listItems}
        loadingListItems={this.props.loadingListItems}
        clicked={this.listItemClickHandler}
        markdone={this.listItemMarkDoneClickHandler}
        onDelete={this.listItemDeleteClickHandler}
        authenticated={this.props.idToken}
        listItemDeletingKey={this.state.listItemDeletingKey}
        isDeleteModalVisible={this.state.isDeleting}
        confirmDelete={this.listItemDeleteConfirmedHandler}
        cancelDelete={this.listItemDeleteCanceledHandler}
        onCreateButtonClick={this.createListItemHandler}
        isCreateListItemButtonHidden={
          this.state.isCreatingListItem ||
          this.state.isUpdating ||
          this.state.isDeleting
        }
        isListModalVisible={this.state.isListModalVisible}
      />
    );

    const listModal = (
      <ListModal
        isListModalVisible={this.state.isListModalVisible}
        listModalAction={this.state.listModalAction}
        onListModalCancel={this.listModalCancelHandler}
        listModalInputChangeHandler={this.listModalInputChangeHandler}
        listNameModalInputValue={this.state.newListName}
        onListSubmit={
          this.state.listModalAction === 'createList'
            ? this.listCreateSubmitHandler
            : this.listUpdateSubmitHandler
        }
      />
    );

    const listOfLists = (
      <ListOfLists
        lists={this.props.lists}
        currentListName={this.props.currentListName}
        clicked={this.listNameClickHandler}
        loadingLists={this.props.loadingLists}
        onCreateList={this.listCreateClickHandler}
        onUpdateList={this.listUpdateClickHandler}
        onDelete={this.listDeleteClickHandler}
        isDeleteListModalVisible={this.state.isListDeleting}
        listDeleting={this.state.listDeleting}
        confirmDelete={this.listDeleteConfirmedHandler}
        cancelDelete={this.listDeleteCanceledHandler}
        isListModalVisible={this.state.isListModalVisible}
      />
    );

    const listEditorForm = (this.state.isCreatingListItem ||
      this.state.isListItemTextUpdating) && (
      <ListEditorModal>
        <ListEditorForm
          onSubmit={this.listItemFormSubmitHandler}
          updating={this.state.isUpdating}
          listItemUpdating={this.state.listItemUpdating}
          listItemText={this.state.listItemText}
          onChange={this.textAreaInputChangeHandler}
          submitButtonCaption={submitButtonCaption}
          onCancel={this.cancelListItemEditingHandler}
        />
      </ListEditorModal>
    );

    const editor = (
      <div>
        {error}
        {listModal}
        {listOfLists}
        {listEditorForm}
        {listOfItems}
      </div>
    );

    const login = '>> Prihlásenie << ';

    const loginMessage = (
      <div className="ListEditor">
        <br />
        Pre prístup k zoznamom
        <br />
        <br />
        <NavLink to="/login">{login}</NavLink>
      </div>
    );

    return this.props.isAuthenticated ? editor : loginMessage;
  }
}

const mapStateToProps = state => ({
  listItems: state.listEditor.listItems,
  loadingListItems: state.listEditor.loadingListItems,
  lists: state.listEditor.lists,
  currentListName: state.listEditor.currentListName,
  currentList: state.listEditor.currentList,
  loadingLists: state.listEditor.loadingLists,
  isListModalVisible: state.listEditor.isListModalVisible,
  error: state.listEditor.error,
  idToken: state.auth.idToken,
  userId: state.auth.userId,
  isAuthenticated: state.auth.idToken !== null,
  listModalAction: state.listEditor.listModalAction,
});

const mapDispatchToProps = dispatch => ({
  onCreateListItem: (listItem, idToken) => {
    dispatch(actions.createListItem(listItem, idToken));
  },
  onUpdateListItem: (listItem, idToken) => {
    dispatch(actions.updateListItem(listItem, idToken));
  },
  onFetchListItems: (listId, idToken) => {
    dispatch(actions.fetchListItems(listId, idToken));
  },
  onDeleteListItem: (listItemKey, idToken) => {
    dispatch(actions.deleteListItem(listItemKey, idToken));
  },
  onInitFetchLists: (idToken, userId) => {
    dispatch(actions.fetchLists(idToken, userId));
  },
  onSetCurrentList: (list, listName) => {
    dispatch(actions.setCurrentList(list, listName));
  },
  onCreateList: (listName, idToken, userId) => {
    dispatch(actions.createList(listName, idToken, userId));
  },
  onUpdateList: (list, idToken) => {
    dispatch(actions.updateList(list, idToken));
  },
  onDeleteList: (list, idToken) => {
    dispatch(actions.deleteList(list, idToken));
  },
  onError: error => {
    dispatch(actions.setError(error));
  },
});

/**
 *  mapDispatchToProps can be just an object... tried it, works, what about that?
 const mapDispatchToProps =  {
        onCreateListItem: (listItem, idToken) => actions.listItemCreate(listItem, idToken),
        onUpdateListItem: (listItem, idToken) => actions.updateListItem(listItem, idToken),
        onInitFetchListItems: (idToken, userId) => actions.fetchListItems(idToken, userId),
        onDeleteListItem: (listItemKey, idToken) => actions.deleteListItem(listItemKey, idToken)
};
 */

export default connect(mapStateToProps, mapDispatchToProps)(ListEditor);
