import React from 'react';
import styled from 'styled-components';
import { version } from '../../../package.json';

const Version = styled.div`
  font-style: italic;
  font-size: xx-small;
  background: ${props => props.theme.lightGray};
`;

const TimestampWhite = styled.div`
  font-size: xx-small;
  color: ${props => props.theme.mainWhite};
`;

const StyledFooter = styled.footer`
  margin-top: 20px;
  text-align: center;
`;

const Footer = ({ timestamp }) => (
  <StyledFooter>
    <Version>{`ver. ${version}`}</Version>
    <TimestampWhite>{timestamp}</TimestampWhite>
  </StyledFooter>
);

export default Footer;
