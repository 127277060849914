import React from 'react';
import styled from 'styled-components';

const StyledSideMenuToggle = styled.div`
  width: 40px;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
  margin: 0 5px 0 auto;
  //border: cadetblue solid 1px;

  div {
    height: 4px;
    margin: 5px;
    background-color: ${props => props.theme.mainDark};
  }

  @media (min-width: 500px) {
    display: none;
  }
`;

const sideMenuToggle = props => (
  <StyledSideMenuToggle onClick={props.clicked}>
    <div />
    <div />
    <div />
  </StyledSideMenuToggle>
);

export default sideMenuToggle;
