import * as actionTypes from './actionTypes';
import axiosInstance from '../../axios-list-items';
import { mockMode } from '../../configuration/configuration';
import { mockData } from '../../configuration/mockData';
import { filterByList } from '../storeUtility';

// action creators
const createListItemStart = () => ({
  type: actionTypes.CREATE_LIST_ITEM_START,
});

const createListItemSuccess = (responseData, listItem) =>
  // ES6 allows to use variables as object keys using []
  // for the newly created item Firebase generates a unique key and returns it like responseData.name
  ({
    type: actionTypes.CREATE_LIST_ITEM_SUCCESS,
    payload: { [responseData.name]: listItem },
  });
const createListItemFail = error => ({
  type: actionTypes.CREATE_LIST_ITEM_FAIL,
  payload: error,
});

// async call with thunk middleware
// middleware sits between dispatching an action and action reaching the reducer
// allows to return a function that will eventually dispatch an action
export const createListItem = (listItem, idToken) => dispatch => {
  dispatch(createListItemStart());
  // console.log("createListItem listItem ", listItem);
  if (mockMode) {
    dispatch(createListItemSuccess(Math.random(), listItem));
  } else {
    axiosInstance
      .post(`listItems.json?auth=${idToken}`, listItem)
      .then(response => {
        // console.log('actions - listItemCreate', response.data);
        dispatch(createListItemSuccess(response.data, listItem));
      })
      .catch(err => {
        console.error('actions - createListItem ', err);
        dispatch(createListItemFail(err));
      });
  }
};

// action creators
const fetchListItemsStart = () => ({
  type: actionTypes.FETCH_LIST_ITEMS_START,
});

const fetchListItemsSuccess = responseData => ({
  type: actionTypes.FETCH_LIST_ITEMS_SUCCESS,
  payload: responseData,
});
const fetchListItemsFail = error => ({
  type: actionTypes.FETCH_LIST_ITEMS_FAIL,
  payload: error,
});

export const fetchListItems = (listId, idToken) => dispatch => {
  dispatch(fetchListItemsStart());

  if (mockMode) {
    dispatch(
      fetchListItemsSuccess(filterByList(mockData.mockListItems, listId))
    );
  } else {
    const queryParams = `?auth=${idToken}&orderBy="listId"&equalTo="${listId}"`;
    axiosInstance
      .get(`listItems.json${queryParams}`)
      .then(response => {
        // console.log('actions - fetchListItems', response.data);
        dispatch(fetchListItemsSuccess(response.data));
      })
      .catch(err => {
        console.error('actions - fetchListItems ', err);
        dispatch(fetchListItemsFail(err.response.data.error));
      });
  }
};

// action creators
const updateListItemStart = () => ({
  type: actionTypes.UPDATE_LIST_ITEM_START,
});

const updateListItemSuccess = responseData => ({
  type: actionTypes.UPDATE_LIST_ITEM_SUCCESS,
  payload: responseData,
});
const updateListItemFail = error => ({
  type: actionTypes.UPDATE_LIST_ITEM_FAIL,
  payload: error,
});

export const updateListItem = (listItem, idToken) => dispatch => {
  dispatch(updateListItemStart());

  axiosInstance
    .patch(`listItems.json?auth=${idToken}`, listItem)
    .then(response => {
      // console.log('actions - updateListItem response.data', response.data);

      dispatch(updateListItemSuccess(response.data));
    })
    .catch(err => {
      console.error('actions - updateListItem ', err);
      dispatch(updateListItemFail(err));
    });
};

const deletelistItemStart = () => ({
  type: actionTypes.DELETE_LIST_ITEM_START,
});

const deleteListItemSuccess = listItemKey => ({
  type: actionTypes.DELETE_LIST_ITEM_SUCCESS,
  payload: listItemKey,
});

const deleteListItemFail = error => ({
  type: actionTypes.DELETE_LIST_ITEM_FAIL,
  payload: error,
});
export const deleteListItem = (listItemKey, idToken) => dispatch => {
  dispatch(deletelistItemStart());

  axiosInstance
    .delete(`listItems/${listItemKey}.json?auth=${idToken}`)
    .then(() => {
      // console.log('actions - deleteListItem success ', response);

      dispatch(deleteListItemSuccess(listItemKey));
    })
    .catch(err => {
      console.error('actions - deleteListItem error', err);
      dispatch(deleteListItemFail(err));
    });
};

const fetchListsStart = () => ({
  type: actionTypes.FETCH_LIST_NAMES_START,
});

const fetchListsSuccess = lists => ({
  type: actionTypes.FETCH_LISTS_SUCCESS,
  payload: lists,
});
const fetchListsFail = error => ({
  type: actionTypes.FETCH_LIST_NAMES_FAIL,
  payload: error,
});

export const fetchLists = (idToken, userId) => dispatch => {
  dispatch(fetchListsStart());

  if (mockMode) {
    // console.log("in fetchLists mockData.lists ", mockData.lists)
    dispatch(fetchListsSuccess(mockData.mockLists));
  } else {
    const queryParams = `?auth=${idToken}&orderBy="userId"&equalTo="${userId}"`;

    axiosInstance
      .get(`lists.json${queryParams}`)
      .then(response => {
        // console.log('actions, fetchLists', Object.values(response.data));
        dispatch(fetchListsSuccess(response.data));
      })
      .catch(err => {
        console.error('actions, error - fetchLists', err);
        dispatch(fetchListsFail(err));
      });
  }
};

export const setCurrentList = (list, listName) => ({
  type: actionTypes.SET_CURRENT_LIST,
  payload: { list, listName },
});

const createListStart = () => ({
  type: actionTypes.CREATE_LIST_START,
});

const createListSuccess = (responseData, newList) => ({
  type: actionTypes.CREATE_LIST_SUCCESS,
  payload: { [responseData.name]: newList },
});

const createListFail = error => ({
  type: actionTypes.CREATE_LIST_FAIL,
  payload: error,
});

export const createList = (newListName, idToken) => dispatch => {
  dispatch(createListStart());

  axiosInstance
    .post(`lists.json?auth=${idToken}`, newListName)
    .then(response => {
      // console.log('actions - createList', response);
      dispatch(createListSuccess(response.data, newListName));
    })
    .catch(err => {
      console.error('actions - createList', err);
      dispatch(createListFail(err));
    });
};

const updateListStart = () => ({
  type: actionTypes.UPDATE_LIST_START,
});

const updateListSuccess = responseData => ({
  type: actionTypes.UPDATE_LIST_SUCCESS,
  payload: responseData,
});
const updateListFail = error => ({
  type: actionTypes.UPDATE_LIST_FAIL,
  payload: error,
});

export const updateList = (list, idToken) => dispatch => {
  dispatch(updateListStart());

  axiosInstance
    .patch(`lists.json?auth=${idToken}`, list)
    .then(response => {
      // console.log('actions - updateList response.data', response.data);
      dispatch(updateListSuccess(response.data));
    })
    .catch(err => {
      console.error('actions - updateList ', err);
      dispatch(updateListFail(err));
    });
};

const deleteListStart = () => ({
  type: actionTypes.DELETE_LIST_START,
});

const deleteListSuccess = listId => ({
  type: actionTypes.DELETE_LIST_SUCCESS,
  payload: listId,
});

const deleteListFail = error => ({
  type: actionTypes.DELETE_LIST_FAIL,
  payload: error,
});
export const deleteList = (list, idToken) => dispatch => {
  dispatch(deleteListStart());

  const listId = list[0];
  const { listName } = list[1];

  // check if the list is empty
  const queryParams = `?auth=${idToken}&orderBy="listId"&equalTo="${listId}"`;
  axiosInstance
    .get(`listItems.json${queryParams}`)
    .then(response => {
      // console.log("listItems before deleting ", response.data);
      deleteList(response.data);
    })
    .catch(err => {
      console.error('actions - listDelete error', err);
    });

  const deleteList = listItems => {
    if (Object.keys(listItems).length !== 0) {
      dispatch(
        deleteListFail(
          `Zoznam ${listName} nie je prázdny. Pred zmazaním zoznamu vymažte položky.`
        )
      );
    } else {
      const listId = list[0];

      axiosInstance
        .delete(`lists/${listId}.json?auth=${idToken}`)
        .then(() => {
          // console.log('actions - listDelete ');
          dispatch(deleteListSuccess(listId));
        })
        .catch(err => {
          console.error('actions - listDelete error', err);
          dispatch(deleteListFail(err));
        });
    }
  };
};

const setErrorSuccess = error => ({
  type: actionTypes.SET_ERROR,
  payload: error,
});

export const setError = error => dispatch => dispatch(setErrorSuccess(error));
