import React from 'react';
import connect from 'react-redux/es/connect/connect';
import './Home.css';
import { NavLink } from 'react-router-dom';
import AuthMessage from '../Auth/AuthMessage/AuthMessage';

const home = props => {
  let homeView = <AuthMessage />;

  if (props.isAuthenticated) {
    homeView = (
      <div>
        <NavLink className="HomeSubtitle" to="list-editor">
          <p>Nákupný zoznam</p>
        </NavLink>
        <NavLink className="HomeTitle" to="list-editor">
          Čo nakúpiť
        </NavLink>
      </div>
    );
  }
  return homeView;
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.idToken !== null,
});

export default connect(mapStateToProps, null)(home);
