import React, { Component } from 'react';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';
import { connect } from 'react-redux';
import Header from '../../components/Header/Header';
import SideMenu from '../../components/Navigation/SideMenu/SideMenu';
import {
  autoemail,
  autologin,
  autopass,
} from '../../configuration/configuration';
import * as actions from '../../store/actions';

const theme = {
  mainBlue: '#2a2a72',
  lightBlue: '#009ffd',
  mainWhite: '#ffffff',
  mainDark: '#232528',
  mainBlack: '#000000',
  mainYellow: '#ffa400',
  lightGray: '#efefef',
  thinGray: '#cccccc',
  hoverGray: '#7e7e7e',
  mainGray: '#4c4c4c',
  error: '#b31b12',
  maxWidth: '700px',
  bs: '0 12 px 24px 0 rgba(0, 0, 0, 0.09)',
};

const Page = styled.div`
  background: ${props => props.theme.mainWhite};
  color: ${props => props.theme.mainBlack};
  white-space: pre-line;
`;

const Inner = styled.div`
  max-width: ${props => props.theme.maxWidth};
  padding: 10px 0;
  margin: 0 auto;
`;

const Main = styled.main`
  text-align: center;
`;

createGlobalStyle`
    html {
        box-sizing: border-box;
        font-size: 10px;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }
    body {
    
    }
`;

class Layout extends Component {
  state = {
    isSideMenuShown: false,
  };

  componentDidMount() {
    if (autologin) {
      this.props.onAuth(autoemail, autopass, false);
    }
  }

  sideMenuCloseHandler = () => {
    this.setState({
      isSideMenuShown: false,
    });
  };

  sideMenuToggleClickHandler = () => {
    this.setState(prevState => ({
      isSideMenuShown: !prevState.isSideMenuShown,
    }));
  };

  render() {
    const { isAuthenticated, children } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Page>
          <Inner>
            <Header
              isAuthenticated={isAuthenticated}
              toggleSideMenu={this.sideMenuToggleClickHandler}
            />
            {this.state.isSideMenuShown && (
              <SideMenu
                close={this.sideMenuCloseHandler}
                isAuthenticated={isAuthenticated}
              />
            )}
            <Main>{children}</Main>
          </Inner>
        </Page>
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  onAuth: (email, password, isSigningUp) =>
    dispatch(actions.auth(email, password, isSigningUp)),
});

export default connect(null, mapDispatchToProps)(Layout);
