import React from 'react';
import styled from 'styled-components';

const ButtonBasic = styled.button`
  margin: 20px 15px 5px 10px;
  padding: 3px;
`;

const Button = ({ visible, disabled, clicked, children, type }) =>
  visible ? (
    <ButtonBasic type={type} disabled={disabled} onClick={clicked}>
      {children}
    </ButtonBasic>
  ) : null;

export default Button;
